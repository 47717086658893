import request from "@/utils/request";
const controller = "/VocationalEducation/InternshipLayout";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });

const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: { id }
  });

const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const update = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
const deleteLayout = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: { id }
  });
const submit = id =>
  request({
    url: `${controller}/Submit`,
    method: "post",
    params: { id }
  });
const getMajorList = params =>
  request({
    url: `${controller}/GetMajorList`,
    method: "get",
    params
  });
const getGradeList = params =>
  request({
    url: `${controller}/GetGradeList`,
    method: "get",
    params
  });
const getClassInfoList = params =>
  request({
    url: `${controller}/GetClassInfoList`,
    method: "get",
    params
  });
const getStudentList = params =>
  request({
    url: `${controller}/GetStudentList`,
    method: "get",
    params
  });
const getStudentListByMajor = params =>
  request({
    url: `${controller}/GetStudentListByMajor`,
    method: "get",
    params
  });

const getEnterpriseList = params =>
  request({
    url: `${controller}/GetEnterpriseList`,
    method: "get",
    params
  });

const insertProjectClass = data =>
  request({
    url: `${controller}/InsertProjectClass`,
    method: 'post',
    data
  });
const updateProjectClass = data =>
  request({
    url: `${controller}/UpdateProjectClass`,
    method: 'post',
    data
  });

const getAllList = enterpriseId =>
  request({
    url: `${controller}/GetAllList${enterpriseId ? `?enterpriseId=${enterpriseId}` : ''}`,
    method: 'get'
  });
const getTeacherProjectClass = _ =>
  request({
    url: `${controller}/GetTeacherProjectClass`,
    method:'get'
  })


export {
  getPageList,
  getInfo,
  insert,
  update,
  deleteLayout,
  submit,
  getMajorList,
  getGradeList,
  getClassInfoList,
  getStudentList,
  getStudentListByMajor,
  getEnterpriseList,

  insertProjectClass,
  updateProjectClass,
  getAllList,
  getTeacherProjectClass
};
