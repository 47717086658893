import request from "@/utils/request";
const controller = "/VocationalEducation/SignInApply";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/getAllList`,
    method: "get",
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });

const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const update = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
const deleteApply = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: {
      id
    }
  });
const approveApply = params =>
  request({
    url: `${controller}/ApproveApply`,
    method: "post",
    params
  });
export {
  getPageList,
  getAllList,
  getInfo,
  insert,
  update,
  deleteApply,
  approveApply
};