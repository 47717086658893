<template>
  <div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading" height="640px">
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <div v-if="item.prop === 'ApplyTime'">
              {{ formartDate(scope.row["ApplyTime"], "all") }}
            </div>
            <el-tag v-else-if="item.prop === 'Status'" class="tag" :type="scope.row[item.prop] | statusFilter">
              {{ scope.row[item.prop] | statusTextFilter }}</el-tag>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scoped">
          <el-button type="text" icon="el-icon-edit-outline" class="btn-color" @click="handleApprove(scoped.row.Id)" v-if="scoped.row.Status !== 4 && scoped.row.Status !== 0">审批</el-button>
          <el-button type="text" icon="el-icon-view" class="btn-color" @click="handleView(scoped.row.Id)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="补卡审批" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div v-loading="dialogLoading">
        <el-row class="u-flex">
          <div class="mr-8">发起学生：</div>
          <div>{{ applyInfo.StudentName }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">发起时间：</div>
          <div>{{ applyInfo.CreationTime }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">申请类型：</div>
          <div>补卡</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">补卡时间：</div>
          <div>
            {{ applyInfo.ApplyTime }}
          </div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">申请原因：</div>
          <div>{{ applyInfo.ApplyReason }}</div>
        </el-row>
        <!-- <el-row class="u-flex">
        <div class="mr-8">附件：</div>
      </el-row> -->
        <el-row v-if="applyInfo.Status !== 4">
          <div class="mr-8">老师回复：</div>
          <el-input type="textarea" resize="none" class="u-flex-1" rows="3" v-model="applyInfo.Remark" :disabled="isView"></el-input>
        </el-row>
      </div>
      <span slot="footer" class="form-footer">
        <el-button v-if="!isView" type="primary" @click="handleSubmit(4)" :loading="btnLoading">通过</el-button>
        <el-button v-if="!isView" type="danger" @click="handleSubmit(0)" :loading="btnLoading">驳回</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPageList, getInfo, approveApply } from "@/api/signInApply";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "danger",
        1: "",
        2: "warning",
        4: "success"
      };
      if (statusMap[status]) {
        return statusMap[status];
      } else {
        return "";
      }
    },
    statusTextFilter(status) {
      const statusMap = {
        0: "驳回",
        1: "已提交",
        2: "审批中",
        4: "审批通过"
      };
      if (statusMap[status]) {
        return statusMap[status];
      }
    }
  },
  props: {
    params: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  mixins: [contentTools],
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      dialogLoading: true,
      loading: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "学生姓名",
          prop: "StudentName"
        },
        // {
        //   label: "岗位",
        //   prop: "JobName"
        // },
        {
          label: "申请原因",
          prop: "ApplyReason"
        },
        {
          label: "发起时间",
          prop: "ApplyTime"
        },
        {
          label: "状态",
          prop: "Status"
        }
      ],
      applyInfo: "",
      isView: false
    };
  },
  watch: {
    params: {
      handler(val) {
        if (val.Date) {
          let [startTime, endTime] = val.Date;
          this.queryParams.StartTime = startTime;
          this.queryParams.EndTime = endTime;
        } else {
          this.queryParams.StartTime = "";
          this.queryParams.EndTime = "";
        }
        this.initialize();
      },
      deep: true
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        // if (this.RoleType === StaffTypeEnum.School) {
        //   let temp = this.employee.find(el => el.StaffType === this.RoleType);
        //   this.queryParams.SchoolId = temp.Employer.Id;
        // } else if (this.RoleType === StaffTypeEnum.Enterprise) {
        //   let temp = this.employee.find(el => el.StaffType === this.RoleType);
        //   this.queryParams.EnterpriseId = temp.Employer.Id;
        // } else {
        //   return null;
        // }
        await this.getTableData();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async handleSubmit(status) {
      try {
        this.btnLoading = true;
        let params = {};
        params.id = this.applyInfo.Id;
        params.status = status;
        const res = await approveApply(params);
        if (res.IsSuccess) {
          this.$message.success("审批成功");
          this.initialize();
          this.handleClose();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async getApplyInfo(id) {
      try {
        this.dialogLoading = true;
        const res = await getInfo(id);
        if (res.IsSuccess) {
          this.applyInfo = res.Result;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleApprove(id) {
      this.dialogVisible = true;
      this.getApplyInfo(id);
    },
    handleView(id) {
      this.isView = true;
      this.dialogVisible = true;
      this.getApplyInfo(id);
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  display: flex;
  margin: 16px 0;
}
</style>
