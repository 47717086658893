<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>项目班：</span>
        <el-select class="mr-20" v-model="queryParams.LayoutId" clearable>
          <el-option v-for="item in projectClassList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
        </el-select>
        <span>日期：</span>
        <el-date-picker v-model="queryParams.Date" class="mr-20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
        <!-- <el-button type="primary" @click="handleSearch">搜索</el-button> -->
      </div>
      <div class="right">
        <div :class="activeComponent === item.component ? 'item active' : 'item'" v-for="item in routeList" :key="item.label" @click="changeComponent(item.component)">
          {{ item.label }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <component :is="activeComponent" :params="queryParams"></component>
    </transition>
  </div>
</template>

<script>
import Leave from "./components/Leave.vue";
import SignIn from "./components/SignIn.vue";
import { getAllList as getProjectClassList } from "@/api/internshipLayout";
export default {
  components: {
    Leave,
    SignIn
  },

  data() {
    return {
      projectClassList:[],
      queryParams: { PageSize: 999, PageIndex: 1, Date: '' },
      routeList: [
        { label: "请假审批", component: "Leave" },
        { label: "补卡审批", component: "SignIn" }
      ],
      activeComponent: "Leave"
    };
  },
  created() {
    this.getProjectClassList();
   },
  methods: {
    changeComponent(name) {
      this.activeComponent = name;
      this.queryParams.Date = [];
    },
    async getProjectClassList() {
      const { Result = [] } = await getProjectClassList();
      this.projectClassList = Result;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.right {
  display: flex;
  .item {
    border: 1px solid #e4e7ed;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 8px;
    margin-right: 8px;
    &:hover {
      color: #fff;
      background-color: #3d5afe;
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
  .active {
    color: #fff;
    background-color: #3d5afe;
    border: 1px solid transparent;
    cursor: pointer;
  }
}
</style>
